<template>
  <Cell has-padding-inline :center="center" class="cell_header">
    <div ref="target" class="button" :class="{ alignRight }">
      <img v-if="image" :src="image" class="button__image" />
      <Icon v-if="icon" :icon="icon" style="color: var(--gray-3)" />
      <div class="button__text">
        <slot></slot>
      </div>
      <div v-if="isSortable" class="button__sort" :class="{ isSortActive }">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 320 512"
          class="button__sort__icon"
          :class="{ isDown: sortOrder === 'desc' }"
        >
          <!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
          <path
            d="M182.6 137.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8l256 0c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z"
          />
        </svg>
      </div>
    </div>
  </Cell>
</template>

<script setup lang="ts">
const target = ref();

defineProps<{
  sortOrder?: "asc" | "desc";
  isSortable?: boolean;
  isSortActive?: boolean;
  image?: string;
  alignRight?: boolean;
  center?: boolean;
  icon?: string;
}>();
</script>

<style scoped lang="scss">
.cell_header {
  .button__text {
    color: var(--gray-2);
  }
}

.button {
  display: flex;
  align-items: center;
  height: 48px;
  gap: 8px;
  border-radius: 5px;
  transition: var(--transition-1);
  white-space: nowrap;
  overflow: hidden;

  &:hover {
    .button__sort {
      opacity: 0.7;
    }
  }
  .button__sort.isSortActive {
    opacity: 1;
  }
  &.alignRight {
    margin-left: auto;
  }

  &.center {
    justify-content: center;
  }

  &__image {
    height: 18px;
    width: 18px;
  }

  &__text {
    font-size: var(--size-16);
    line-height: 24px;
    color: var(--gray-1);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__sort {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    width: 16px;
    color: var(--gray-2);
    opacity: 0;

    &.light {
      color: var(--gray-4);
    }

    &__icon {
      height: 12px;
      width: 12px;
      path {
        fill: var(--gray-2);
      }

      &.isDown {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
